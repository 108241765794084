<script setup>

</script>
<template>
    <div class="home-banner"></div>
    <div class="container-fluid p-5 pb-2">
        <div class="row">
            <div class="col-md-6">
                <div class="logo text-start">
                    <img src="../assets/logo.png" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
    <div class="home-content-container container-fluid d-flex flex-column justify-content-center">
        <div class="row">
            <div class="col-md-12" style="z-index: 1000">
                <div class="d-flex align-items-center p-4">
                    <div class="text-center" style="margin: 0 auto;width: 100%">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'english'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">ENGLISH</div>
                                </router-link>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'romanian'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">ROMANIAN</div>
                                </router-link>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'punjabi'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">PUNJABI</div>
                                </router-link>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'urdu'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">URDU</div>
                                </router-link>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'portuguese'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">PORTUGUESE</div>
                                </router-link>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'spanish'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">SPANISH</div>
                                </router-link>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'arabic'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">ARABIC</div>
                                </router-link>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'polish'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">POLISH</div>
                                </router-link>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-2 text-center my-4">
                                <router-link :to="{name:'start',query:{lang:'welsh'}}" class="button btn-home d-block d-flex">
                                    <div class="text-center w-100 d-flex flex-column justify-content-center p-3">WELSH</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.icon-product-information {
    max-width: 65px;
    position: relative;
}
.btn-become-smoke-free {
    border: 5px solid #ffffff;
    outline: 5px solid #E96324;
    -moz-border-radius: 600px;
    background-color: #E96324;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 600px;
    font-size:3.2vw;
    font-weight: bold;
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 30px;
    padding: 20px 20px;
}
span.btn-icon {
    width: 70px;
    height: 70px;
    margin: 7px 0 0 7px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
}
.facts {
    background: url("../assets/facts.png");
}
.testimonials {
    background: url("../assets/testimonials.png");
}
.calculator {
    background: url("../assets/calculator.png");
}
.resource {
    background: url("../assets/trusted-partners.png");
}
.logo {
//margin-top: 30px;
    width: 75%;
//position: relative;
//top: 130px;
}
.logo img {
    max-height: 200px;
    margin: 0 auto;
}
.settings-trigger {
    display: inline-table;
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
}
.smoke-free {
    z-index: 1000;
}
.home-banner {
    height: 643px;
    width: 100%;
    background-size: cover !important;
    position: absolute;
    top:-295px;
    background: url("../assets/header-new.png") no-repeat top;
    z-index: -100;
}
.line-height-50 {

}
.button {
    background-color: transparent;
    color: #818386;
    border: 3px solid #E96324;
}
.button:active {
    background-color: #E96324;
    color: #ffffff;
}
.button:focus {
    margin: 2px;
    outline: 3px solid #E96324;
    border: 3px solid #ffffff;
}
</style>