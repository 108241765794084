<script setup>
import {nextTick, onBeforeMount, onDeactivated, onMounted, ref} from "vue";
import {get} from "@/storage";
import NumberPad from "@/components/NumberPad.vue";

const input = ref('');
const error = ref(false);

// eslint-disable-next-line no-undef
const emit = defineEmits(['answered']);

onBeforeMount(() => {
    nextTick(() => {
        document.title = 'Question 3';
    });
});

onMounted(() => {
    const value = get('question-3');

    if (value) {
        input.value = get('question-3').answer;
    }
})

const validate = () => {
    if ( ! isNaN(parseInt(input.value))) {
        error.value = false;
        return false;
    }
    if (parseInt(input.value) < 1) {
        error.value = false;
        return false;
    }
}

const updateInput = (key) => {
    error.value = false;
    if (parseInt(key) > 0) {
        console.log('Is a number');
        input.value = `${input.value}${key}`;
        handleChange();
        validate();
    } else {
        error.value = false;
        input.value = '';
    }
}

const handleChange = () => {
    if ( ! validate()) {
        error.value = true;
    }
    emit('answered', {
        number: 3,
        question:'How many do you smoke per day?',
        answer:input.value,
        valid:!validate(),
    });
}

onDeactivated(() => {
    error.value = false;
});
</script>

<template>
    <div class="question">
        <h1 class="mb-5">How many do you smoke per day?</h1>
        <div>
            <input readonly type="number" min="1" max="100" v-model="input" @input="handleChange" @change="validate()" />
        </div>
        <div class="mt-5" v-if="error">
            <div class="alert alert-danger">
                Please enter a quantity above 1
            </div>
        </div>
        <NumberPad @number-pressed="updateInput" />
    </div>
</template>

<style scoped>

</style>