<script setup>
import {nextTick, onBeforeMount, onDeactivated, onMounted, ref} from "vue";
import {get} from "@/storage";
// eslint-disable-next-line no-undef
const emit = defineEmits(['answered']);

const input = ref(null);
const error = ref(false);
const answers = ref([]);

onBeforeMount(() => {
    nextTick(() => {
        document.title = 'Question 6';
    });
});

onMounted(() => {
    const value = get('question-6');

    if (value) {
        input.value = get('question-6').answer;
    }
});

const handleChange = (value) => {
    if (value === '') {
        error.value = true;
    }

    if (answers.value.length === 3 && answers.value.indexOf(value) === -1) return;

    if (answers.value.indexOf(value) >= 0) {
        answers.value.splice(answers.value.indexOf(value), 1);
    } else {
        answers.value.push(value);
    }

    answers.value = [...new Set(answers.value)];

    input.value = value;
    emit('answered', {
        number: 6,
        question:'Which methods have you tried?',
        answer:JSON.stringify(answers.value),
        valid:true,
        finished:true,
    });
}

onDeactivated(() => {
    error.value = false;
});
</script>

<template>
    <div class="question">
        <h1 class="">
            Which methods have you tried?
        </h1>
        <div class="text-sm-center text-muted mb-5">Select upto 3</div>
        <div class="row">
            <div class="col-md-3">
                <button type="button" :class="{active: answers.indexOf('Cold Turkey') >= 0}" class="mx-auto button d-block my-1" @click="handleChange('Cold Turkey')">
                    Cold Turkey
                </button>
            </div>
            <div class="col-md-3">
                <button type="button" :class="{active: answers.indexOf('Vaping') >= 0}" class="mx-auto button d-block my-1" @click="handleChange('Vaping')">
                    Vaping
                </button>
            </div>
            <div class="col-md-3">
                <button type="button" :class="{active: answers.indexOf('Nicotine Gum') >= 0}" class="mx-auto button d-block my-1" @click="handleChange('Nicotine Gum')">
                    Nicotine Gum
                </button>
            </div>
            <div class="col-md-3">
                <button type="button" :class="{active: answers.indexOf('Nicotine Tablets') >= 0}" class="mx-auto button d-block my-1" @click="handleChange('Nicotine Tablets')">
                    Nicotine Tablets
                </button>
            </div>
            <div class="col-md-3">
                <button type="button" :class="{active: answers.indexOf('Nicotine Patches') >= 0}" class="mx-auto button d-block my-1" @click="handleChange('Nicotine Patches')">
                    Nicotine Patches
                </button>
            </div>
            <div class="col-md-3">
                <button type="button" :class="{active: answers.indexOf('Hypnotherapy') >= 0}" class="mx-auto button d-block my-1" @click="handleChange('Hypnotherapy')">
                    Hypnotherapy
                </button>
            </div>
            <div class="col-md-3">
                <button type="button" :class="{active: answers.indexOf('Smoking Clinic') >= 0}" class="mx-auto button d-block my-1" @click="handleChange('Smoking Clinic')">
                    Smoking Clinic
                </button>
            </div>
            <div class="col-md-3">
                <button type="button" :class="{active: answers.indexOf('Other') >= 0}" class="mx-auto button d-block my-1" @click="handleChange('Other')">
                    Other
                </button>
            </div>
        </div>
        <div class="alert alert-danger" v-if="error">
            Please chose one of the options
        </div>
    </div>
</template>

<style scoped>
.button {
    font-size: 1.0rem;
}
</style>