import axios from 'axios';
import {Database} from "@/database";

class http {
    constructor(settings) {

        this.instance = axios.create({
            baseURL: process.env.VUE_APP_API_HOST,
            timeout: 5000,
            headers: {'x-machine-key': settings?.api_key ?? 'NYMIV-IQTHQ-LDYDK-QK4LA'}
        });
    }

    get = async (uri) => {
        return await this.instance.get(uri).then((response) => {
            return response.data;
        });
    }

    post = async (uri, data) => {
        return await this.instance.post(uri, data).then((response) => {
            return response.data;
        });
    }
}

const db = new Database('app');
const settings = await db.get('settings', 'settings');
export default new http(settings);