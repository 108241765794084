<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
    partner:Object
});
</script>

<template>
        <fieldset class="card-fieldset d-flex flex-column my-2">
            <legend>
                <img :src="`/img/logos/${props.partner.logo}`" class="img-fluid" />
            </legend>
            <div class="card-content">
                <p>
                    {{ props.partner.bio }}
                </p>
                <div class="text-start mb-2">
                    <span>Type:</span> {{ props.partner.type }}
                </div>
                <div class="text-start">
                    <span>Products:</span> {{ props.partner.products }}
                </div>
            </div>
        </fieldset>
</template>

<style scoped>
img {
    max-height: 55px;
}
span {
    color: #139BD3;
    font-weight: bold;
}
fieldset {
    border: 2px solid #139BD3;
    border-radius: 8px;
    flex: 0 1 auto;
}
fieldset legend {
    padding: 10px;
    margin-left: 10px;
    text-align: left;
    float: none;
    width: auto;
}
.card-content {
    padding: 10px 30px;
}
.card-content p {
    text-align: left;
    font-size: 1.1rem;
}
.card-fieldset {

}
</style>