const check = (key) => {
    return (localStorage.getItem(key) !== null);
}

const save = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

const get = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

const clear = () => {
    localStorage.clear();
}

export {
    check,
    save,
    get,
    clear
}