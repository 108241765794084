<script setup>
// eslint-disable-next-line no-undef
const emit = defineEmits(['number-pressed']);

const pressed = (key) => {
    emit('number-pressed', key);
}
</script>

<template>
    <div class="">
        <div class="number-pad w-75 mx-auto">
            <div class="d-flex justify-content-around">
                <div class="key d-flex align-items-center" @click="pressed(1)">
                    <div class="align-self-center">1</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(2)">
                    <div class="align-self-center">2</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(3)">
                    <div class="align-self-center">3</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(4)">
                    <div class="align-self-center">4</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(5)">
                    <div class="align-self-center">5</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(6)">
                    <div class="align-self-center">6</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(7)">
                    <div class="align-self-center">7</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(8)">
                    <div class="align-self-center">8</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(9)">
                    <div class="align-self-center">9</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(0)">
                    <div class="align-self-center">0</div>
                </div>
                <div class="key d-flex align-items-center" @click="pressed(null)">
                    <div class="align-self-center">Clear</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mx-auto {
    margin: 0 auto;
}
.number-pad {
    margin-top: 10px;
    //border: 1px solid #2c3e50;
    border-radius: 8px;
    padding: 5px;
}
.number-pad .key {
    text-align: center;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    width: 110px;
    height: 45px;
    margin: 5px;
    font-weight: bold;
    cursor: pointer;
    background-color: #f2f2f2;
    color: #2b2839;
}
.number-pad .key:hover, .number-pad .key:active {
    background-color: rgba(152, 210, 255, 0.67);
}
.key div {
    width: 100%;
    text-align: center;
}
</style>