<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
    item: Object
})
</script>

<template>
    <div class="card _mb-4 d-flex flex-column">
        <div class="card-body text-center">
            <h2>{{ props.item.name}}, {{ props.item.age }}</h2>
            <div class="d-flex justify-content-around gap-1 p-2">
                <div><span class="badge badge-item">Smoked Daily: <strong>{{ props.item.smoked_per_day }}</strong></span></div>
                <div><span class="badge badge-item">Smoke-Free: <strong>{{ props.item.smoke_free }}yrs</strong></span></div>
            </div>
            <p>
                {{ props.item.comment }}
            </p>
        </div>
    </div>
</template>

<style scoped>
    .badge.badge-item {
        padding: 5px 15px;
        background-color: #348bd8;
        border-radius: 600px;
        display: block;
    }
    .card {
        border: 3px solid #139BD3;
        border-radius: 8px;
        flex: 0 1 auto;
    }
    h2 {
        color: #348bd8;
    }
    .badge {
        background-color: #2b2839;
    }
</style>