import { createRouter, createWebHistory } from 'vue-router'
import LanguageView from "@/views/LanguageView.vue";
import HomeView from '../views/HomeView.vue'
import BecomeSmokeFreeView from "@/views/BecomeSmokeFreeView.vue";
import KnowTheFactsView from '../views/KnowTheFactsView.vue'
import FactsView from "@/views/FactsView.vue";
import SavingsCalculatorView from "@/views/SavingsCalculatorView.vue";
import TrustedResourcesView from "@/views/TrustedResourcesView.vue";
import TestimonialsView from "../views/TestimonialsView.vue";
import ProductInformationView from "@/views/ProductInformationView.vue";
import ResultsPage from "@/views/ResultsPage.vue";
import InformationView from "@/views/InformationView.vue";
import Smokefree2030View from "@/views/Smokefree2030View.vue";
import SettingsView from "@/views/SettingsView.vue";
import {nextTick} from 'vue';
// import {Database} from "@/database";

// const db = new Database('app');
//
// const getCampaign = async () => {
//   const response = await db.get('settings', 'settings');
//
//   return `utm_source=${response.name ?? 'default'}&utm_medium=app`;
// };
//
// const campaign = shallowRef(await getCampaign());

const routes = [
  {
    path: '/',
    name: 'home',
    component: LanguageView,
    meta: {title: 'Make the Switch'},
  },
  {
    path: `/start`,
    name: 'start',
    component: HomeView,
    meta: {title: 'Start'},
  },
  {
    path: `/become-smoke-free`,
    name: 'become-smoke-free',
    component: BecomeSmokeFreeView,
    meta: {title: 'Become Smoke Free'},
  },
  {
    path: `/results`,
    name: 'results',
    component: ResultsPage,
    meta: {title: 'Results'}
  },
  {
    path: `/know-the-facts`,
    name: 'know-the-facts',
    component: KnowTheFactsView,
    meta: {title: 'Know the Facts'}
  },
  {
    path: `/facts`,
    name: 'facts',
    component: FactsView,
    meta: {title: 'Fact'}
  },
  {
    path: `/testimonials`,
    name: 'testimonials',
    component: TestimonialsView,
    query: {machine: '1'},
    meta: {title: 'Testimonials'}
  },
  {
    path: `/savings-calculator`,
    name: 'savings-calculator',
    component: SavingsCalculatorView,
    meta: {title: 'Savings Calculator'}
  },
  {
    path: `/trusted-retailers`,
    name: 'trusted-retailers',
    component: TrustedResourcesView,
    meta: {title: 'Trusted Retailers'}
  },
  {
    path: `/product-information`,
    name: 'product-information',
    component: ProductInformationView,
    meta: {title: 'Product Information'}
  },
  {
    path: `/information`,
    name: 'information',
    component: InformationView,
    meta: {title: 'Information'}
  },
  {
    path: `/smokefree-2030`,
    name: 'smokefree-2030',
    component: Smokefree2030View,
    meta: {title: 'Smoke-Free 2030'}
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: HomeView,
    meta: {title: '404'}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to) => {
  if (to.name !== 'settings') {
    return true;
  } else {
    const password = window.prompt('Enter Password');

    return !(password === null || password === '' || password !== process.env.VUE_APP_SETTINGS_PASSCODE);
  }
});

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title || 'Make The Switch a smoke-free future';
  });
});

export default router