<script setup>
import PageTitle from "@/components/PageTitle.vue";
import {partners} from '@/trustedPartners.js';
import {onMounted, ref} from "vue";
import RetailersCarousel from "@/components/RetailersCarousel.vue";

const retailerItems = ref({});

const getTypes = (type) => {
    return partners().filter((item) => {
        return item.type === type;
    });
}

const filterRetailers = (value) => {
    if (value === 'all') {
        retailerItems.value = partners();
    } else {
        retailerItems.value = getTypes(value);
    }
}

onMounted(() => {
    retailerItems.value = partners();
});
</script>

<template>
    <div>
        <PageTitle />
        <div class="content" style="height: calc(100vh - 300px);margin-top: 30px">
            <div>
                <div class="row">
                    <div class="col-md-4">
                        <button type="button" class="px-3 py-3 button d-block" @click="filterRetailers('all')">
                            SHOW ALL RETAILERS
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" class="px-3 py-3 button d-block" @click="filterRetailers('in-store')">
                            SHOW IN-STORE RETAILERS
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" class="px-3 py-3 button d-block" @click="filterRetailers('online')">
                            SHOW ONLINE RETAILERS
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 _card-stretch">
                    <RetailersCarousel :items="retailerItems" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card-stretch {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}
.button {
    font-size: 1.5vw;
    background-color: #E96324;
}
</style>