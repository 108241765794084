<script setup>
import {onMounted, ref, watchEffect} from "vue";
import {clear} from "@/storage";
import router from "@/router";
const start = () => {
    clear();
}
const settingsTriggerCount = ref(0);

watchEffect(() => {
    if (settingsTriggerCount.value === 10) {
        router.push('/settings');
    }
});

onMounted(() => {
    setInterval(() => settingsTriggerCount.value = 0, 15000);
});
</script>
<template>
    <div class="settings-trigger" @click="settingsTriggerCount++"></div>
    <div class="home-banner"></div>
    <div class="container-fluid p-5 pb-2">
        <div class="row">
            <div class="col-md-6">
                <div class="logo text-start">
                    <img src="../assets/logo.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-end">
                    <div class="d-flex gap-4 justify-content-end">
                        <router-link :to="{name:'home'}">
                            <div class="btn-language">
                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="65" height="65" fill="#ffffff">
                                    <path d="m7.508,9.196c-.107-.464-.403-.886-.842-1.07-.206-.086-.413-.126-.613-.126-.689,0-1.287.475-1.443,1.147l-1.475,6.252c-.072.307.16.601.476.601.226,0,.422-.155.475-.375l.389-1.625h3.115l.374,1.621c.051.222.249.379.476.379h.012c.314,0,.546-.292.476-.598l-1.422-6.206Zm-2.793,3.804l.869-3.627c.051-.219.244-.373.469-.373.073,0,.149.016.226.049.111.046.213.197.253.371l.827,3.58h-2.645Zm14.805-9H4.52C2.038,4,.02,6.019.02,8.5v7c0,2.481,2.019,4.5,4.5,4.5h15c2.481,0,4.5-2.019,4.5-4.5v-7c0-2.481-2.019-4.5-4.5-4.5ZM1.02,15.5v-7c0-1.93,1.57-3.5,3.5-3.5h7v14h-7c-1.93,0-3.5-1.57-3.5-3.5Zm22,0c0,1.93-1.57,3.5-3.5,3.5h-7V5h7c1.93,0,3.5,1.57,3.5,3.5v7Zm-1-6.009v.018c0,.271-.22.491-.491.491h-.546c-.107.917-.517,2.904-2.085,4.341.701.354,1.574.595,2.658.648.26.013.464.229.464.49v.018c0,.281-.235.505-.516.491-1.469-.071-2.605-.448-3.484-.986-.879.538-2.015.915-3.484.986-.28.014-.516-.21-.516-.491v-.018c0-.26.204-.477.464-.49,1.085-.054,1.957-.295,2.659-.648-.54-.495-.943-1.055-1.243-1.614-.176-.329.059-.727.432-.727.181,0,.345.1.432.259.291.534.695,1.066,1.257,1.519,1.474-1.185,1.862-2.925,1.964-3.777h-5.473c-.271,0-.491-.22-.491-.491v-.018c0-.271.22-.491.491-.491h3.009v-.509c0-.271.22-.491.491-.491h.018c.271,0,.491.22.491.491v.509h3.009c.271,0,.491.22.491.491Z"/>
                                </svg>
                            </div>
                        </router-link>
                        <router-link :to="{name:'information'}">
                            <img src="../assets/product-information.png" class="img-fluid icon-product-information" alt="Product Information" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="home-content-container container-fluid d-flex flex-column justify-content-center">
        <div class="row">
            <div class="col-md-12" style="z-index: 1000">
                <div class="d-flex align-items-center p-4">
                    <div class="text-center" style="margin: 0 auto;width: 100%">
                        <div class="row">
                            <div class="col-md-12" style="margin-bottom: 10px;">
                                <router-link :to="{name:'become-smoke-free'}" class="btn-become-smoke-free" @click="start">
                                    Click here to become smoke-free
                                </router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 text-center my-2">
                                <router-link :to="{name:'know-the-facts'}" class="button btn-home d-block d-flex">
                                    <span class="btn-icon facts d-flex flex-column justify-content-center"></span>
                                    <div class="text-center w-100 d-flex flex-column justify-content-center">Know the facts</div>
                                </router-link>
                            </div>
                            <div class="col-lg-6 col-md-6 text-center my-2">
                                <router-link :to="{name:'testimonials'}" class="button btn-home d-block d-flex">
                                    <span class="btn-icon testimonials"></span>
                                    <div class="text-center w-100 d-flex flex-column justify-content-center">Testimonials</div>
                                </router-link>
                            </div>
                            <div class="col-lg-6 col-md-6 text-center my-2">
                                <router-link :to="{name:'savings-calculator'}" class="button btn-home d-block d-flex">
                                    <span class="btn-icon calculator"></span>
                                    <div class="text-center w-100 d-flex flex-column justify-content-center">Savings Calculator</div>
                                </router-link>
                            </div>
                            <div class="col-lg-6 col-md-6 text-center my-2">
                                <router-link :to="{name:'trusted-retailers'}" class="button btn-home d-block d-flex">
                                    <span class="btn-icon resource"></span>
                                    <div class="text-center w-100 d-flex flex-column justify-content-center">Trusted Retailers</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .icon-product-information {
        max-width: 65px;
        position: relative;
    }
    .btn-become-smoke-free {
        border: 5px solid #ffffff;
        outline: 5px solid #E96324;
        -moz-border-radius: 600px;
        background-color: #E96324;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 600px;
        font-size:3.2vw;
        font-weight: bold;
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 30px;
        padding: 20px 20px;
    }
    span.btn-icon {
        width: 70px;
        height: 70px;
        margin: 7px 0 0 7px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .facts {
        background: url("../assets/facts.png");
    }
    .testimonials {
        background: url("../assets/testimonials.png");
    }
    .calculator {
        background: url("../assets/calculator.png");
    }
    .resource {
        background: url("../assets/trusted-partners.png");
    }
    .logo {
        //margin-top: 30px;
        width: 75%;
        //position: relative;
        //top: 130px;
    }
        .logo img {
            max-height: 200px;
            margin: 0 auto;
        }
    .settings-trigger {
        display: inline-table;
        position: absolute;
        right: 10px;
        width: 20px;
        height: 20px;
    }
    .smoke-free {
        z-index: 1000;
    }
 .home-banner {
     height: 643px;
     width: 100%;
     background-size: cover !important;
     position: absolute;
     top:-295px;
     background: url("../assets/header-new.png") no-repeat top;
     z-index: -100;
 }
 .line-height-50 {

 }
 .button {
     background-color: transparent;
     color: #818386;
     border: 3px solid #E96324;
 }
.button:active {
    background-color: #E96324;
    color: #ffffff;
}
.button:focus {
    margin: 2px;
    outline: 3px solid #E96324;
    border: 3px solid #ffffff;
}
</style>