<script setup>
import PageTitle from "@/components/PageTitle.vue";
import {ref, shallowRef} from "vue";
import {save, get} from "@/storage";
import QuestionOne from "@/components/Questions/QuestionOne.vue";
import QuestionTwo from "@/components/Questions/QuestionTwo.vue";
import QuestionThree from "@/components/Questions/QuestionThree.vue";
import QuestionFour from "@/components/Questions/QuestionFour.vue";
import QuestionFive from "@/components/Questions/QuestionFive.vue";
import QuestionSix from "@/components/Questions/QuestionSix.vue";
import router from "@/router";

const max               = 5;
const questionCount     = ref(1);
const currentQuestion   = shallowRef(QuestionOne);
const recorded          = ref(false);
const showWaysToStop    = ref(false);
const hide              = ref(false);
const finished          = ref(false);

const recordAnswer = (value) => {
    finished.value = false;

    save('question-' + value.number, value);
    if (value.valid) {
    // if (value !== '') {
        // save('question-' + value.number, value);
        recorded.value = true;

        if (value.finished !== undefined) {
            finished.value = true;
        }
    }
    recorded.value = false;

    showWaysToStop.value = questionCount.value === 5 && value.answer === 'Yes';
}

const next = () => {
    recorded.value = false;
    const question = get('question-' + questionCount.value);
    if (question && question.valid) {
        recorded.value = (question.answer !== '');
    }

    if (questionCount.value === max) return;
    if (recorded.value) {
        questionCount.value++;
        loadQuestion();
        recorded.value = false;
    }
    recorded.value = false;
}

const back = () => {
    recorded.value = false;
    finished.value = false;
    showWaysToStop.value = false;
    if (questionCount.value === 1) return;
    questionCount.value--;
    loadQuestion();
}
const loadQuestion = () => {
    switch (questionCount.value)
    {
        case 1:
            currentQuestion.value = QuestionOne;
            break;
        case 2:
            currentQuestion.value = QuestionTwo;
            break;
        case 3:
            currentQuestion.value = QuestionThree;
            break;
        case 4:
            currentQuestion.value = QuestionFour;
            break;
        case 5:
            currentQuestion.value = QuestionFive;
            break;
        default:
            currentQuestion.value = QuestionOne;
    }
}

const toggleResults = () => {
    router.push({name: 'results'});
}

window.addEventListener('keydown', (event) => {
    if (event.code === 'Enter' || event.code === '13' || event.key === 'Enter') {
        next();
    }
});
</script>

<template>
    <div>
        <PageTitle title="Become Smoke-Free" />
        <div class="margin-bottom"></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 text-center" style="margin-top: -30px;margin-bottom: 20px;">
                    <div class="question-count text-muted text-center" v-if="!hide">
                        <h2>QUESTION {{ questionCount }} OF {{ max }}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="question">
                        <div class="d-flex align-items-center" style="height: calc(100vh - 400px);width: 100%;max-width: 1000px;margin: 0 auto;">
                            <div class="text-center" style="width: 100%">
                                <div>
                                    <keep-alive>
                                        <component :is="currentQuestion" @answered="recordAnswer" @results="toggleResults" @finished="finished = true"></component>
                                    </keep-alive>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nav-container" v-if="!hide">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6 btn-back">
                    <button type="button" class="btn btn-app btn-outline-secondary" @click="back">
                        Back
                    </button>
                </div>
                <div class="col-6 btn-next">
                    <button type="button" class="btn btn-app btn-success" :disabled="recorded" @click="currentQuestion = QuestionSix" v-if="showWaysToStop">
                        Next
                    </button>
                    <button type="button" class="btn btn-app btn-success" v-else-if="finished" @click="toggleResults">
                        Finish
                    </button>
                    <button type="button" class="btn btn-app btn-success" :disabled="recorded" @click="next" v-else>
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.btn-next button, .btn-back button {
    background-color: transparent;
    color: #000;
    border-radius: 600px;
    padding: 12px 50px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.3rem;
    //position: absolute;
    //top: 19px;
}
.btn-next button {
    border: 3px solid #198754;
}
.btn-back button {
    border: 3px solid #999;
}
.nav-container {
    position: absolute;
    //background-color: #eee;
    padding: 22px 0;
    height: 100px;
    bottom: 0;
    width: 100%;
}
</style>