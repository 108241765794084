import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueClosable from "vue-closable/vue-closable";

createApp(App)
    .use(router)
    .use(VueClosable)
    .mount('#app')