<script setup>
import PageTitle from "@/components/PageTitle.vue";
import Slider from "@vueform/slider/src/Slider";
// import
import {ref} from "vue";

const form = ref({
    cost:0,
    pack_size:1,
    smoke_in_a_day:1,
    years_smoking:0,
});

const calculation = ref({
    weekly_cost: 0,
    monthly_cost: 0,
    yearly_cost: 0,
    five_year_cost: 0,
    ten_year_cost: 0,
    life_time:0,
    tar: 0,
    save_year: 0,
    save_three_year: 0,
    save_five_year: 0,
    save_ten_year: 0,
    save_fifty_year: 0,
});

const calculateTar = (value) => {
    if (value === undefined) return;

    return (value * 7);
}

const calculate = () => {
    const weekly_cost       = (((parseInt(form.value.cost) / parseInt(form.value.pack_size)) * parseInt(form.value.smoke_in_a_day)) * 7);
    const monthly_cost      = (weekly_cost * 4);
    const yearly_cost       = (monthly_cost * 12);
    const five_year_cost    = (yearly_cost * 5);
    const ten_year_cost     = (five_year_cost * 2);

    const saving_year       = (yearly_cost);
    const saving_three_year = (saving_year * 3);
    const saving_five_year  = (saving_year * 5);
    const saving_ten_year   = (saving_year * 10);
    const saving_fifty_year = (saving_year * 50);

    const GBPound = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
    });

    calculation.value.tar = calculateTar(parseInt(form.value.smoke_in_a_day));

    calculation.value.weekly_cost    = GBPound.format((isNaN(weekly_cost) ? 0 : weekly_cost.toFixed(2)));
    calculation.value.monthly_cost   = GBPound.format((isNaN(monthly_cost) ? 0 : monthly_cost.toFixed(2)));
    calculation.value.yearly_cost    = GBPound.format((isNaN(yearly_cost) ? 0 : yearly_cost.toFixed(2)));
    calculation.value.five_year_cost = GBPound.format((isNaN(five_year_cost) ? 0 : five_year_cost.toFixed(2)));
    calculation.value.ten_year_cost  = GBPound.format((isNaN(ten_year_cost) ? 0 : ten_year_cost.toFixed(2).toLocaleString()));

    calculation.value.life_time = GBPound.format(((isNaN(yearly_cost) ? 0 : yearly_cost) * form.value.years_smoking).toFixed(2));

    calculation.value.save_year = GBPound.format((isNaN(saving_year) ? 0 : saving_year.toFixed(2)));
    calculation.value.save_three_year = GBPound.format((isNaN(saving_three_year) ? 0 : saving_three_year.toFixed(2)));
    calculation.value.save_five_year = GBPound.format((isNaN(saving_five_year) ? 0 : saving_five_year.toFixed(2)));
    calculation.value.save_ten_year = GBPound.format((isNaN(saving_ten_year) ? 0 : saving_ten_year.toFixed(2)));
    calculation.value.save_fifty_year = GBPound.format((isNaN(saving_fifty_year) ? 0 : saving_fifty_year.toFixed(2)));
}
</script>

<template>
    <div>
        <PageTitle title="Savings Calculator" />
        <div class="margin-bottom"></div>
        <div class="d-flex align-items-center justify-content-center" style="height:calc(100vh - 180px);">
            <div class="content">
                <div class="row">
                    <div class="col-md-6 col d-flex flex-column justify-content-between slider-col">
                        <div class="slider-container">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="w-80">
                                        <div class="slider-title">1. HOW MUCH IN POUNDS STERLING DO YOU PAY FOR A PACK OF CIGARETTES?</div>
                                        <Slider v-model="form.cost" value="1" :min="parseInt(1)" :max="parseInt(50)" showTooltip="drag" class="px-4" @update="calculate()" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="d-flex align-items-end justify-content-center h-100">
                                    <span class="badge bg-primary text-center slider-number">
                                        {{ form.cost }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slider-container">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="w-80">
                                        <div class="slider-title">2. HOW MANY CIGARETTES ARE IN A PACK?</div>
                                        <Slider v-model="form.pack_size" value="1" :min="parseInt(1)" :max="parseInt(50)" showTooltip="drag" class="px-4" @update="calculate()" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="d-flex align-items-end justify-content-center h-100">
                                    <span class="badge bg-primary text-center slider-number">
                                        {{ form.pack_size }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slider-container">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="w-80">
                                        <div class="slider-title">3. HOW MANY CIGARETTES DO YOU SMOKE A IN A DAY?</div>
                                        <Slider v-model="form.smoke_in_a_day" value="1" :min="parseInt(1)" :max="parseInt(100)" showTooltip="drag" class="px-4" @update="calculate()" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="d-flex align-items-end justify-content-center h-100">
                                    <span class="badge bg-primary text-center slider-number">
                                        {{ form.smoke_in_a_day }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slider-container">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="w-80">
                                        <div class="slider-title">4. HOW MANY YEARS HAVE YOU BEEN SMOKING?</div>
                                        <Slider v-model="form.years_smoking" value="0" :min="parseInt(0)" :max="parseInt(100)" showTooltip="drag" class="px-4" @update="calculate()" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="d-flex align-items-end justify-content-center h-100">
                                    <span class="badge bg-primary text-center slider-number">
                                        {{ form.years_smoking }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="results">
                                    <h2>YOU ARE SPENDING</h2>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                WEEKLY
                                            </div>
                                            <span class="badge bg-primary rounded-pill">{{ calculation.weekly_cost }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                MONTHLY
                                            </div>
                                            <span class="badge bg-primary rounded-pill">{{ calculation.monthly_cost }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                YEARLY
                                            </div>
                                            <span class="badge bg-primary rounded-pill">{{ calculation.yearly_cost }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                5 YEAR
                                            </div>
                                            <span class="badge bg-primary rounded-pill">{{ calculation.five_year_cost }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                10 YEAR
                                            </div>
                                            <span class="badge bg-primary rounded-pill">{{ calculation.ten_year_cost }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                TO DATE
                                            </div>
                                            <span class="badge bg-primary rounded-pill">{{ calculation.life_time }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="results results-savings">
                                    <h2>YOU COULD SAVE</h2>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                YEARLY
                                            </div>
                                            <span class="badge bg-warning">{{ calculation.save_year }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                3 YEARS
                                            </div>
                                            <span class="badge bg-warning">{{ calculation.save_three_year }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                5 YEARS
                                            </div>
                                            <span class="badge bg-warning">{{ calculation.save_five_year }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                10 YEARS
                                            </div>
                                            <span class="badge bg-warning">{{ calculation.save_ten_year }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-start">
                                            <div class="ms-2 me-auto">
                                                50 YEARS
                                            </div>
                                            <span class="badge bg-warning">{{ calculation.save_fifty_year }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <p class="text-muted small mt-2">
                                    *based on UK average age of death, this is what you could save if you quit smoking today
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.content {
    width: 100%;
}
h2 {
    font-size: 1.2rem;
}
.slider-col {
    max-height: calc(100vh - 200px);
}
.slider-container:not(:first-child) {
    //margin-top: 100px;
    //height: 300px;
}
.slider-title {
    //height: 55px;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 10px;
    margin-top: 30px;
}
.slider-number {
    font-size: 1.2vw;
    font-weight: bold;
    min-width: 60px;
    border: 2px solid #139BD3;
    border-radius: 8px;
    background-color: transparent !important;
    color: #2c3e50;
    padding: 25px 0;
}
.results {
    background-color: #F2F2F2;
    padding: 20px;
    border-radius: 8px;
}
.results ul li {
    text-align: left;
    font-size: 1.2rem;
    padding: 20px 0;
    background-color: transparent;
}
.results ul li:not(:last-child) {
    //border-bottom: 1px solid #818386;
}
.results ul li span {
    font-size: 0.9rem;
}
.results ul li span.badge {
    font-size: 1.6rem;
    background-color: transparent !important;
    color: #139BD3;
    font-weight: bold;
    padding: 0;
}
.results-savings ul li span.badge {
    color: #E96324;
}
</style>