const facts = () => {
    return [
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : '5.4 million people in England smoke tobacco which remains the single biggest cause of preventable illness and death.',
        },
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : 'Up to 2 out of 3 lifelong smokers will die from smoking and recent data shows 1 in 4 deaths from all cancers were estimated to be from smoking.',
        },
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : 'After just 48 hours of stopping, there’s no nicotine in the body and quitters may start noticing that things smell and taste better. Within 1 to 9 months of quitting, coughing and shortness of breath decrease. After 1 year, the added risk of a heart attack falls to about half of that of a smoker’s.',
        },
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : 'National data shows that smoking is also closely associated with poor mental health and well-being.',
        },
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : 'Smokers score worse than the population as a whole on every mental well-being indicator.',
        },
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : 'Second hand smoke is dangerous for anyone exposed to it, but children are especially vulnerable due to breathing more rapidly and having less developed airways, lungs and immune systems.',
        },
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : 'Smoking is the single most important modifiable risk factor in pregnancy. Smoking is associated with a range of poor pregnancy outcomes including miscarriage, stillbirth, premature birth, neonatal complications, low birth weight and sudden infant death syndrome',
        },
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : 'People with a mental health condition are just as likely to want to stop smoking as those without, but they are more likely to be addicted to smoking and more likely to think it will be difficult to quit.',
        },
        {
            'category'  : 'Health',
            'name'      : 'Government',
            'fact'      : 'Within 10 seconds of your first puff, the toxic chemicals in tobacco smoke reach your brain, heart and other organs',
        },
        {
            'category'  : 'Spending',
            'name'      : 'Government',
            'fact'      : 'In 2021 to 2022, £68 million of public health grant fund was spent on stop smoking services by local authorities and nearly 100,000 people quit with the support of a stop smoking service.',
        },
        {
            'category'  : 'Spending',
            'name'      : 'Government',
            'fact'      : '£35 million has been committed to the NHS this year so that all smokers admitted to hospital will be offered NHS-funded tobacco treatment services.',
        },
        {
            'category'  : 'Lifestyle',
            'name'      : 'Government',
            'fact'      : 'Vaping has become the most popular quitting aid in England, and vapes are extremely effective for many, particularly when combined with additional behavioural support from SSS. They are up to twice as effective as the available licensed nicotine replacement therapy at one-fifth of the cost. The latest international research shows that smokers who use a vape every day are 3 times more likely to quit smoking, even if they did not intend to.',
        },
        {
            'category'  : 'Lifestyle',
            'name'      : 'Government',
            'fact'      : 'Smoking is not a lifestyle choice but a dependency requiring treatment',
        },
        {
            'category'  : 'Health',
            'name'      : 'NHS',
            'fact'      : `Every year around 76,000 people in the UK die from smoking, with many more living with debilitating smoking-related illnesses.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'NHS',
            'fact'      : `Smoking increases your risk of developing more than 50 serious health conditions.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'NHS',
            'fact'      : `Smoking causes 17% of all deaths in people aged 35 and over.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'NHS',
            'fact'      : `Recent data shows 1 in 4 deaths from all cancers were estimated to be from smoking.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'NHS',
            'fact'      : `Almost 6 million people in England smoke, and tobacco remains the single biggest cause of preventable illness and death.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'NHS',
            'fact'      : `A child who's exposed to passive smoke is at increased risk of developing chest infections, meningitis, a persistent cough and, if they have asthma, their symptoms will get worse. They're also at increased risk of sudden infant death syndrome (SIDS), sometimes called cot death, and an ear infection called glue ear.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'NHS',
            'fact'      : `If you have never smoked but you have a spouse who smokes, your risk of developing lung cancer increases by about a quarter.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'NHS',
            'fact'      : `Smoking causes around 7 out of every 10 cases of lung cancer (70%).`,
        },
        {
            'category'  : 'Lifestyle',
            'name'      : 'NHS',
            'fact'      : `Using a vaping product is the most popular aid used by people trying to quit smoking. In 2020, 27.2% of people used a vaping product in a quit attempt in the previous 12 months. This compares with 15.5% who used NRT over the counter or on prescription (2.7%), and 4.4% who used varenicline. Vaping is positively associated with quitting smoking successfully. In 2017, over 50,000 smokers stopped smoking with a vaping product who would otherwise have carried on smoking.`,
        },
        {
            'category'  : 'Environment',
            'name'      : 'WHO',
            'fact'      : `Every year the tobacco industry costs the world more than 8 million human lives, 600 million trees, 200,000 hectares of land, 22 billion tonnes of water and 84 million tonnes of CO2.`,
        },
        {
            'category'  : 'Environment',
            'name'      : 'WHO',
            'fact'      : `The majority of tobacco is grown in low-and-middle-income countries, where water and farmland are often desperately needed to produce food for the region. Instead, they are being used to grow deadly tobacco plants, while more and more land is being cleared of forests.`,
        },
        {
            'category'  : 'Environment',
            'name'      : 'WHO',
            'fact'      : `The WHO report “Tobacco: Poisoning our planet” highlights that the industry’s carbon footprint from production, processing and transporting tobacco is equivalent to one-fifth of the CO2 produced by the commercial airline industry each year, further contributing to global warming.`,
        },
        {
            'category'  : 'Environment',
            'name'      : 'WHO',
            'fact'      : `“Tobacco products are the most littered item on the planet, containing over 7000 toxic chemicals, which leech into our environment when discarded. Roughly 4.5 trillion cigarette filters pollute our oceans, rivers, city sidewalks, parks, soil and beaches every year,” said Dr Ruediger Krech, Director of Health Promotion at WHO.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'WHO',
            'fact'      : `Tobacco kills up to half of its users who don’t quit.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'WHO',
            'fact'      : `Tobacco kills more than 8 million people each year, including an estimated 1.3 million non-smokers who are exposed to second-hand smoke.`,
        },
        {
            'category'  : 'Health',
            'name'      : 'WHO',
            'fact'      : `In 2020, 22.3% of the world’s population used tobacco: 36.7% of men and 7.8% of women.`,
        },

    ]
}

export {
    facts,
}