<script setup>
import PageTitle from "@/components/PageTitle.vue";
import 'vue3-carousel/dist/carousel.css'
import {facts} from "@/knowTheFacts";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import KnownFactsCarousel from "@/components/KnownFactsCarousel.vue";

const factsItems = ref({});

const getCategories = (category) => {

    if (category === 'All') {
        return facts();
    }

    return facts().filter((item) => {
        return item.category === category;
    });
}

const getName = (name) => {
    return facts().filter((item) => {
        return item.name === name;
    });
}

const filterFacts = (type, value) => {
    if (type === 'category') return getCategories(value);
    if (type === 'name') return getName(value);
}

onMounted(() => {
    const route = useRoute();

    if (route.query.category !== undefined) {
        factsItems.value = filterFacts('category', route.query.category);
    }

    if (route.query.name !== undefined) {
        factsItems.value = filterFacts('name', route.query.name);
    }
});
</script>

<template>
    <div>
        <PageTitle />
        <div class="content" style="margin-top: 0;height: calc(100vh - 250px);">
            <KnownFactsCarousel :items="factsItems" />
        </div>
    </div>
    <footer>
        <div class="text-center">
            <router-link to="/know-the-facts" class="button px-4 py-2">
                Go Back
            </router-link>
        </div>
    </footer>
</template>

<style scoped>

</style>