<script setup>
import {onMounted, ref, watch} from "vue";
import PageTitle from "@/components/PageTitle.vue";
import productInformation from "@/productInformation";
import {testimonials} from "@/testimonials";
import http from '@/http.js';
import {save, get} from "@/storage.js";
import {currencyFormat} from "@/currency";
import router from "@/router";
import CardTestimonial from "@/components/Cards/CardTestimonial.vue";

const product               = ref(0);
const selectedProducts      = ref([]);
const emailAddress          = ref(null);
const productDescription    = ref([]);
const selectedTestimonial   = ref({});
const emailSuccess          = ref(false);
const emailSending          = ref(false);
const result = ref({
    week            : 0.00,
    month           : 0.00,
    year            : 0.00,
    fiveYears       : 0.00,
    tenYears        : 0.00,
    fifty           : 0.00,
    toDate          : 0.00,
    savingOneYear   : 0.00,
    savingThreeYear : 0.00,
    savingFiveYear  : 0.00,
    savingTenYear   : 0.00,
    savingFiftyYear : 0.00,
});

const getResults = () => {
    // -- Get question 3
    const q1 = get('question-1');
    const q3 = get('question-3');
    const priceSingle = 0.60;

    if (q3 === null) {
        return router.push('/');
    }

    const pricePerWeek = ((priceSingle * parseInt(q3.answer)) * 7);

    result.value = {
        week        : currencyFormat(pricePerWeek),
        month       : currencyFormat(pricePerWeek * 4),
        year        : currencyFormat((pricePerWeek * 52)),
        fiveYears   : currencyFormat((pricePerWeek * 260)),
        tenYears    : currencyFormat((pricePerWeek * 521)),
        fifty       : currencyFormat((pricePerWeek * (52 * 50))),
        toDate      : currencyFormat((pricePerWeek * (52 * parseInt(q1.answer)))),
        savingOneYear   :   currencyFormat((pricePerWeek * 52)),
        savingThreeYear :   currencyFormat((pricePerWeek * (52 * 3))),
        savingFiveYear  :   currencyFormat((pricePerWeek * (52 * 5))),
        savingTenYear   :   currencyFormat((pricePerWeek * (52 * 10))),
        savingFiftyYear :   currencyFormat((pricePerWeek * (52 * 50))),
    }
}

const testimonial = async () => {
    const age = await get('question-1');
    selectedTestimonial.value = testimonials().reduce((prev, curr) => {
         return (Math.abs(curr.age - parseInt(age.answer))) < Math.abs(prev.age - parseInt(age.answer)) ? curr : prev;
    });
}

const products = async () => {
    const usedProducts = await get('question-6');
    let productNames = [];

    if (usedProducts !== null && typeof usedProducts.answer !== undefined) {
        productNames = JSON.parse(usedProducts.answer);
    }

    let i = 1;
    selectedProducts.value = productInformation.filter((product) => {
        if (productNames.indexOf(product.name) === -1 && i <= 3) {
            i++;
            return true;
        }
    });

    productDescription.value = selectedProducts.value[0] ?? [];
}

// eslint-disable-next-line no-unused-vars
watch(product, () => {
    changeProduct();
});

const changeProduct = () => {
    if (selectedProducts.value[product.value] !== undefined) {
        productDescription.value = selectedProducts.value[product.value];
    }
}

const emailResults = async () => {
    try {
        if (emailAddress.value  !== null) {
            if (get('sent')) {
                emailSending.value = true;
                http.post('/api/results/email', {
                    uuid: get('sent'),
                    email: emailAddress.value
                }).then(() => {
                    emailSuccess.value = true;
                }).finally(() => {
                    emailSending.value = false;
                }).catch(() => {

                });
            }
        }
    }
        // eslint-disable-next-line no-empty
    catch (error) {
        alert(error);
    }
}

onMounted(async () => {
    getResults();
    products();
    testimonial();
    changeProduct();
    if ( ! get('sent'))
    {
        const answers = {
            answers: [
                get('question-1'),
                get('question-2'),
                get('question-3'),
                get('question-4'),
                get('question-5'),
                get('question-6'),
            ]
        }

        try {
            await http.post('/api/results', answers).then((response) => {
                save('sent', response.uuid);
            }).catch(() => {
                // console.log(error);
            });
        }
            // eslint-disable-next-line no-empty
        catch (error) {

        }

    }
});
</script>

<template>
    <PageTitle />
    <div class="d-flex align-items-center">
        <div class="content" style="width: 100%;overflow-y: hidden;/*height: calc(100vh - 250px);height: 600px;*/">
            <div class="row mb-4">
                <div class="col-md-12">
                    <div class="d-flex summary">
                        <div class="p-2 flex-fil w-25">
                            <strong>WEEKLY COST</strong>
                            <div class="value">{{ result.week }}</div>
                            <div class="border-value"></div>
                            <strong>1 YEAR SAVINGS</strong>
                            <div class="value-saving">{{ result.savingOneYear }}</div>
                        </div>
                        <div class="p-2 flex-fil w-25">
                            <strong>MONTHLY COST</strong>
                            <div class="value">{{ result.month }}</div>
                            <div class="border-value"></div>
                            <strong>3 YEAR SAVINGS</strong>
                            <div class="value-saving">{{ result.savingThreeYear }}</div>
                        </div>
                        <div class="p-2 flex-fil w-25">
                            <strong>YEARLY COST</strong>
                            <div class="value">{{ result.year }}</div>
                            <div class="border-value"></div>
                            <strong>5 YEAR SAVINGS</strong>
                            <div class="value-saving">{{ result.savingFiveYear }}</div>
                        </div>
                        <div class="p-2 flex-fill w-25">
                            <strong>50 YEAR COST</strong>
                            <div class="value">{{ result.fifty }}</div>
                            <div class="border-value"></div>
                            <strong>10 YEAR SAVINGS</strong>
                            <div class="value-saving">{{ result.savingTenYear }}</div>
                        </div>
                        <div class="p-2 flex-fill w-25">
                            <strong>COST TO DATE</strong>
                            <div class="value">{{ result.toDate }}</div>
                            <div class="border-value"></div>
                            <strong>50 YEAR SAVING</strong>
                            <div class="value-saving">{{ result.savingFiftyYear }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!--       Start         -->
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-9">
                            <h2>Our recommendations</h2>
                            <ul class="list-group list-group-horizontal product-list">
                                <li class="list-group-item d-flex flex-column justify-content-center"
                                    :class="{active: product === index}"
                                    @click="product = index"
                                    v-for="(productEl,index) in selectedProducts"
                                    :key="index">
                                    {{ productEl.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3">
                            <h2>&nbsp;</h2>
                            <button type="button" class="btn-email-results" @click="product = -1">
                                Email your results
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="information-box">
                                <div v-if="product >= 0">
                                    <p class="text-start">
                                        {{ productDescription?.description }}
                                    </p>
                                    <p class="text-start">
                                        Available in store or online from your local <img :src="`/img/logos/${productDescription?.logos}`" class="img-fluid" style="max-height: 30px;" />
                                    </p>
                                </div>
                                <div v-else>
                                    <div>
                                        <div v-if="emailSuccess">
                                            <div class="alert alert-success">
                                                Your results have been emailed to you
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between" v-else>
                                            <input v-model="emailAddress" type="email" class="form-control input-email" placeholder="Enter your email address" />
                                            <button type="button" class="btn btn-success px-5" :class="{disabled:emailSending}" @click="emailResults()">
                                                <span v-if="emailSending">Sending...</span>
                                                <span v-else>SEND</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <h2>&nbsp;</h2>
                    <CardTestimonial :item="selectedTestimonial" />
                </div>
            </div>
                <!--       End         -->

<!--            <div class="row">-->
<!--                <div class="col-md-12">-->
<!--                    <div class="mt-3" style="height: 200px;">-->
<!--                        <div class="d-flex gap-4">-->
<!--                            <h2 class="text-start text-uppercase w-50">Receive your results straight to your inbox</h2>-->
<!--                            <div class="w-50">-->
<!--                                <div v-if="emailSuccess">-->
<!--                                    <div class="alert alert-success">-->
<!--                                        Your results have been emailed to you-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="d-flex justify-content-between" v-else>-->
<!--                                    <input v-model="emailAddress" type="email" class="form-control input-email" placeholder="Enter your email address" />-->
<!--                                    <button type="button" class="btn btn-success px-5" :class="{disabled:emailSending}" @click="emailResults()">-->
<!--                                        <span v-if="emailSending">Sending...</span>-->
<!--                                        <span v-else>SEND</span>-->
<!--                                    </button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
    <div class="margin-bottom"></div>
</template>

<style scoped>
.btn-email-results {
    margin-bottom: 20px;
    background-color: #e96324;
    border: 1px solid #e96324;
    font-weight: 700;
    cursor: pointer;
    color: #fff;
    font-size: 1.2vw;
    width: 100%;
    border-radius: 0.375rem;
    padding: 0.5rem;
}
.border-value {
    border: 1px solid rgba(133, 133, 133, 0.98);
    background-color: rgba(133, 133, 133, 0.98);
    margin: 5px 0;
}
.input-email {
    text-align: left;
    border: 2px solid #348bd8;
    height: 50px;
    margin-right: 10px;
}
.summary {
    //background-color: #e0e0e0;
    padding: 10px;
    border-radius: 8px;
    font-size: 1vw;
}
.link {
    border: 4px solid #348bd8;
    border-radius: 8px;
    padding: 10px;
}
.link a {
    font-size: 2rem !important;
    text-decoration: none;
    font-weight: bold;
    color: #348bd8;
    line-height: 30px;
}
h2, .value, .value-saving {
    color: #348bd8;
    font-weight: bold;
    font-size: 1.8vw;
}
.value, .value-saving {
    font-size: 1.7rem;
}
.value-saving {
    color: #E96324;
}
.product-list li:first-child {
    //margin-top: 0;
}
.product-list li {
    margin-bottom: 20px;
    //border-radius: 8px;
    background-color: #858585;
    border: 1px solid #858585;
    font-weight: bold;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.2vw;
    width: 100%;

}
.product-list li.active {
    background-color: #348bd8;
    border: 1px solid #348bd8;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.product-list li.active::after {
    //content: "";
    //width: 0;
    //height: 0;
    //border-bottom: 21px solid transparent;
    //border-right: 20px solid #348bd8;
    //border-bottom: 21px solid transparent;
    //position: absolute;
    //right: -21px;
    //top: -1px;
}
.information-box, .testimonial-box {
    padding: 20px;
    border-radius: 8px;
    border: 3px solid #348bd8;
}
.testimonial-box {

}
</style>