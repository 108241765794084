const testimonials = () => {
    return [
        {
            name            : 'Nadia',
            age             : 38,
            gender          : 'Female',
            years_smoked    : 12,
            smoked_per_day  : 10,
            smoke_free      : 8,
            method          : 'Cold Turkey',
            comment         : 'Since quitting my asthma has been better, I can exercise for longer. I saved a fortune and I felt healthier.',
        },
        {
            name            : 'Elliot',
            age             : 57,
            gender          : 'Male',
            years_smoked    : 30,
            smoked_per_day  : 45,
            smoke_free      : 12,
            method          : 'Vaping',
            comment         : 'Since quitting, nothing stinks anymore (clothes, apartment, car), food does taste better, and generally feel better health wise.',
        },
        {
            name            : 'Chris',
            age             : 59,
            gender          : 'Male',
            years_smoked    : 35,
            smoked_per_day  : 40,
            smoke_free      : 8,
            method          : 'Hypnosis',
            comment         : 'Paid for a cruise using a smoking cessation app that showed how much money I saved per day. Health better as no coughing every morning.',
        },
        {
            name            : 'Abigail',
            age             : 31,
            gender          : 'Female',
            years_smoked    : 15,
            smoked_per_day  : 12,
            smoke_free      : 1,
            method          : 'Vaping',
            comment         : 'Money has been saved, my sleeves no longer smell like stale cigarettes, but most importantly my “smokers cough” has gone and I had really bad acid reflux issues, which stopped as soon as I quit smoking!',
        },
        {
            name            : 'Nicola',
            age             : 53,
            gender          : 'Female',
            years_smoked    : 18,
            smoked_per_day  : 15,
            smoke_free      : 1,
            method          : 'Vaping',
            comment         : 'Don’t have the lingering smell of ciggies on my clothes, feel better in myself and my health and definitely have more money!',
        },
        {
            name            : 'Rachel',
            age             : 40,
            gender          : 'Female',
            years_smoked    : 24,
            smoked_per_day  : 20,
            smoke_free      : 2,
            method          : 'Vaping',
            comment         : 'I’ve notice how badly people smell after a cigarette! My breathing and skin have improved and I generally feel better overall. I also have saved a lot of money.',
        },

        {
            name            : 'James',
            age             : 40,
            gender          : 'Male',
            years_smoked    : 19,
            smoked_per_day  : 10,
            smoke_free      : 1,
            method          : 'Vaping',
            comment         : 'My health is miles better, I can sleep much better than I used to be able to and my breathing has improved.',
        },
        {
            name            : 'Zack',
            age             : 35,
            gender          : 'Male',
            years_smoked    : 17,
            smoked_per_day  : 12,
            smoke_free      : 2,
            method          : 'Vaping',
            comment         : 'I have been able to save much more money and feel a lot healthier.',
        },
        {
            name            : 'Corinne',
            age             : 35,
            gender          : 'Female',
            years_smoked    : 9,
            smoked_per_day  : 20,
            smoke_free      : 13,
            method          : 'Nicotine patches and cold turkey',
            comment         : 'I feel healthier and not conscious of smelling of smoke. Definitely have more money now considering the current prices. ',
        },
        {
            name            : 'Sam',
            age             : 32,
            gender          : 'Male',
            years_smoked    : 15,
            smoked_per_day  : 20,
            smoke_free      : 2,
            method          : 'Vaping',
            comment         : 'I quit smoking when I learnt we were having a baby as I didn’t want to put them at risk, quitting smoking has improved my sense of smell and taste.',
        },
        {
            name            : 'Nigel',
            age             : 63,
            gender          : 'Male',
            years_smoked    : 15,
            smoked_per_day  : 20,
            smoke_free      : 25,
            method          : 'Cold Turkey',
            comment         : 'Stopped for health reasons was very unfit but stopped  smoking and started running eventually completing London marathon. Also saved a lot of money by stopping.',
        },
        {
            name            : 'Daniel',
            age             : 21,
            gender          : 'Male',
            years_smoked    : 7,
            smoked_per_day  : 20,
            smoke_free      : 3,
            method          : 'Vaping',
            comment         : 'Stopping smoking made me feel so much healthier and happier. It saved me a lot of money. My partner gave me the kick to stop smoking and it’s all I needed, I have been smoke free for 3 years and would never look back',
        },
        {
            name            : 'Neil',
            age             : 42,
            gender          : 'Male',
            years_smoked    : 15,
            smoked_per_day  : 15,
            smoke_free      : 10,
            method          : 'Cold turkey',
            comment         : 'Stopping smoking made me feel great ',
        },
        {
            name            : 'Sarah',
            age             : 37,
            gender          : 'Female',
            years_smoked    : 13,
            smoked_per_day  : 8,
            smoke_free      : 1,
            method          : 'Vaping',
            comment         : 'I no longer smell of smoke all the time, my taste and better VO2 max levels have improved.',
        },
        {
            name            : 'Chris',
            age             : 37,
            gender          : 'Male',
            years_smoked    : 11,
            smoked_per_day  : 15,
            smoke_free      : 8,
            method          : 'Vaping',
            comment         : 'Feel better in myself, healthier, less stress. None of the horrible smell that’s comes with smoking and loving the different flavours with vaping.',
        },
        {
            name            : 'Carrie',
            age             : 41,
            gender          : 'Female',
            years_smoked    : 20,
            smoked_per_day  : 20,
            smoke_free      : 1,
            method          : 'Vaping',
            comment         : 'Since quitting smoking I’ve been able to save money but not as much as I would like due to using vapes but I’m going try and quit them too. I find that my clothes smell a lot nicer and also my house as I used to smoke in the living room.',
        },
        {
            name            : 'Gary',
            age             : 47,
            gender          : 'Male',
            years_smoked    : 20,
            smoked_per_day  : 15,
            smoke_free      : 2,
            method          : 'Nicotine patches',
            comment         : 'I quit smoking prior to my wedding day and haven’t looked back since. Since quitting smoking I have had more energy and my overall health is much better.',
        },
        {
            name            : 'Hannah',
            age             : 28,
            gender          : 'Female',
            years_smoked    : 5,
            smoked_per_day  : 10,
            smoke_free      : 5,
            method          : 'Nicotine spray',
            comment         : 'I suffered with bad acne and since quitting my skin has massively improved. I am saving more money and feel a lot healthier.',
        },
        {
            name            : 'Samantha',
            age             : 62,
            gender          : 'Female',
            years_smoked    : 40,
            smoked_per_day  : 40,
            smoke_free      : 2,
            method          : 'Vaping and hypnotherapy',
            comment         : 'I started smoking because I thought it would help my anxiety, I later went on to quit so I could save money. Once I quit I was able to save money and control my anxiety with other, more healthier methods.',
        },
    ];
}

export {
    testimonials
}