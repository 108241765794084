<script setup>
import {Carousel, Navigation, Slide} from "vue3-carousel";
import CardTestimonial from "@/components/Cards/CardTestimonial.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
    items: Object,
});
</script>

<template>
    <Carousel :items-to-show="2" class="carousel">
        <template #slides>
            <Slide v-for="(item,index) in props.items" :key="index" class="d-flex align-items-stretch">
                <CardTestimonial :item="item" class="w-75" />
            </Slide>
        </template>
        <template #addons>
            <Navigation />
        </template>
    </Carousel>
</template>

<style scoped>

</style>