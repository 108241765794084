<script setup>
import PageTitle from "@/components/PageTitle.vue";
import {facts} from "@/knowTheFacts";
import {onMounted, ref} from "vue";

const fact = ref(null);

const getFact = () => {
    const items = facts();
    fact.value = items[Math.floor(Math.random() * items.length)];
}

onMounted(() => {
    getFact();
    // setInterval(() => getFact(), 6500);
});
</script>

<template>
    <PageTitle />
    <div class="content text-start">
        <div class="margin-bottom"></div>
        <div class="row d-flex align-items-stretch">
            <div class="col-md-6">
                <h2>The Governments Smoke-Free 2030 Initiative</h2>
                <p>
                    The government's Smoke-Free 2030 initiative represents a transformative commitment aimed at creating a tobacco-free society in England by the year 2030. This public health campaign addresses the significant health and economic impacts associated with smoking and looks to improve the overall well-being of the population through smoking cessation. The initiative incorporates a multi level strategy, including tobacco control measures, public awareness campaigns, and increased accessibility to smoking cessation support. By setting a clear target for a smoke-free future, the government aims to reduce the number of smokers, save lives, and alleviate the strain on healthcare resources caused by smoking-related illnesses.
                </p>
            </div>
            <div class="col-md-6 d-flex flex-column justify-content-center" v-if="fact">
                <div class="row d-flex align-items-stretch">
                    <div class="col-md-2 text-end">
                        <svg class="quote-left" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="50" height="50" fill="#E96324">
                            <path d="M8,4H4A4,4,0,0,0,0,8v4a2,2,0,0,0,2,2H7.91A6.006,6.006,0,0,1,2,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,8,4Z"/>
                            <path d="M22,4H18a4,4,0,0,0-4,4v4a2,2,0,0,0,2,2h5.91A6.006,6.006,0,0,1,16,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,22,4Z"/>
                        </svg>
                    </div>
                    <div class="col-md-8 text-center quote">
                        <div class="fact">
                            {{ fact.fact }}
                        </div>
                        <div class="source text-center">
                            - {{ fact.name }} -
                        </div>
                    </div>
                    <div class="col-md-2">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="50" height="50" fill="#E96324">
                            <path d="M8,4H4A4,4,0,0,0,0,8v4a2,2,0,0,0,2,2H7.91A6.006,6.006,0,0,1,2,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,8,4Z"/>
                            <path d="M22,4H18a4,4,0,0,0-4,4v4a2,2,0,0,0,2,2h5.91A6.006,6.006,0,0,1,16,19a1,1,0,0,0,0,2,8.009,8.009,0,0,0,8-8V6A2,2,0,0,0,22,4Z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <div class="text-center">
            <router-link :to="{name:'information'}" class="button px-4 py-2">
                Go Back
            </router-link>
        </div>
    </footer>
</template>

<style scoped>
@media (max-height: 768px) {
    fieldset {
        max-height: 230px;
        overflow-y: auto;
    }
}
.quote {
    font-size: 1.5vw;
    font-weight: bold;
}
.quote-left {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.card-stretch {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}
fieldset {
    border: 3px solid #139BD3;
    border-radius: 8px;
    flex: 0 1 auto;
}
fieldset legend {
    padding: 10px;
    margin-left: 10px;
    text-align: left;
    float: none;
    width: auto;
}
.card-content {
    padding: 10px 30px;
}
.card-content p {
    text-align: left;
    font-size: 1.1rem;
}
.content {
    font-size: 1.4vw;
}
.fact {
    color: #E96324;
}
.source {
    font-style: italic;
}
</style>