<script setup>
// import {nl2br} from "@/views/strHelpers";
// eslint-disable-next-line no-undef
import {onMounted} from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
    product:Object
});

onMounted(() => {
    // console.log(props.product);
});
</script>

<template>
    <div class="carousel__item card-fieldset mx-5 my-2">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ props.product.name}}</h4>
            </div>
            <div class="card-body text-start">
                <p>{{ props.product.what}}</p>

                <h6>How they work</h6>
                <p>{{ props.product.how}}</p>

                <h6>Effectiveness</h6>
                <p>{{ props.product.effect}}</p>

                <h6>Side Effects</h6>
                <p>{{ props.product.side_effect}}</p>

                <h6>How to use</h6>
                <p>{{ props.product.uses}}</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.carousel__item {
    width: 72% !important;
}
.card {
    border: 2px solid #139BD3;
    background-color: #139BD3;
    border-radius: 8px;
}
.card-header {
    background-color: #139BD3;
    color: #ffffff;
    border: none;
}
.card-title {
    font-weight: bold;
}
.card-body {
    max-height: 200px;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #139BD3;
    margin: 2px;
    border-radius: 8px;
}
h6 {
    color: #139BD3;
    font-weight: bold;
}
</style>