const productInformation = () => {
    return [
        {
            name:'Vaping',
            description:`An electronic cigarette or vape is a device that simulates tobacco smoking. It consists of an atomizer, a power source such as a battery, and a container such as a cartridge or tank filled with liquid. Instead of smoke, the user inhales vapor. As such, using an e-cigarette is often called "vaping".`,
            logos: 'totally-wicked.jpg',
        },
        {
            name:'Nicotine Patches',
            description:`Nicotine patches are adhesive patches that deliver a controlled amount of nicotine through the skin and are made available in various strengths, allowing for a gradual reduction in nicotine intake which reduces the withdrawal symptoms. The patch works by adhering to the skin, and nicotine is absorbed slowly which mimics the nicotine delivery from smoking without the harmful chemicals. Nicotine patches are a proven method to help people quit smoking, especially when used as part of a comprehensive smoking cessation program.`,
            logos: 'totally-wicked.jpg',
        },
        {
            name:'Nicotine Gum',
            description:`Nicotine gum is a chewable gum containing a controlled dose of nicotine, it is an over-the-counter NRT. Nicotine is absorbed through the oral mucosa, helping manage withdrawal symptoms. Nicotine gum is effective when used as part of a comprehensive smoking cessation plan.`,
            logos: 'totally-wicked.jpg',
        },
        {
            name:'Cold Turkey',
            description:`Cold Turkey is a method of quitting smoking abruptly without the use of any smoking cessation aids and involves immediate cessation of smoking, relying on willpower and determination to overcome cravings and withdrawal symptoms. The success rates vary, and it can be challenging due to withdrawal symptoms, but some individuals successfully quit without external assistance.`,
            logos: 'totally-wicked.jpg',
        },
        {
            name:'Nicotine Tablets',
            description:`Nicotine Tablets are small, easily dissolvable tablets and when consumed they deliver nicotine through the oral mucosa, offering a quick way to tackle cravings. Nicotine tablets are effective and discreet for managing cravings.`,
            logos: 'totally-wicked.jpg',
        },
        {
            name:'Hypnotherapy',
            description:`Hypnotherapy is a therapeutic approach that uses hypnosis to help individuals quit smoking by altering thought patterns and behaviours. Hypnotherapy includes inducing a relaxed state and suggesting positive changes in behaviour, targeting subconscious triggers associated with smoking. The effectiveness varies, and research on long-term success is limited, but some individuals report positive outcomes.`,
            logos: 'totally-wicked.jpg',
        },
        {
            name:'Smoking Clinic',
            description:`Stop Smoking Clinics are structured programs led by healthcare professionals to support individuals in quitting smoking through counselling. Utilising a combination of counselling, behaviour modification, and NRTs to address psychological and physical aspects of addiction, this method of quitting smoking can be highly effective, particularly when combined with personalised support and follow-up care.`,
            logos: 'totally-wicked.jpg',
        },

    ]
}

export default productInformation();