const currencyFormat = (value) => {
    const currency = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
    });

    return currency.format(value.toFixed(2));
}

export {
    currencyFormat,
}