import Localbase from "localbase";

export class Database {

    constructor(collection) {
        this.db = new Localbase(collection);
        this.db.config.debug = false;
    }

    async add(document, data, key) {
        return await this.db.collection(document).add(data, key??null);
    }

    async get(collection, key){
        return await this.db.collection(collection).doc(key).get();
    }

    async clear() {
        return await this.db.delete();
    }
}