const partners = () => {
    return [
        {
            name    : 'Boots',
            type    : 'in-store',
            logo    : 'boots_logo.png',
            bio     : 'Boots Pharmacy, a leading health and beauty retailer offers a range of Nicotine Replacement Therapy (NRT) products.',
            products    : 'Patches. Gums. Lozenges. Inhalers/Sprays. Vapes',
        },
        {
            name    : 'Lloyds Pharmacy',
            type    : 'in-store',
            logo    : 'lloyds-pharmacy.jpeg',
            bio     : 'Lloyds Pharmacy, a trusted health and wellness pharmacy, offers a range of Nicotine Replacement Therapy products to support individuals in their journey to quit smoking',
            products : 'Patches. Gum. Inhalers. Vapes',
        },
        {
            name    : 'Wells pharmacy',
            type    : 'in-store',
            logo    : 'Well_Pharmacy_logo.png',
            bio     : 'With a focus on promoting healthier lifestyles, Well Pharmacy serves as a reliable partner for individuals seeking personalised smoking cessation support through a variety of Nicotine Replacement Therapy options.',
            products : 'Gum. Lozenges. Patches. Sprays',
        },
        {
            name    : 'Totally wicket vaping',
            type    : 'online',
            logo    : 'totally-wicked.jpg',
            bio     : 'Totally Wicked Vaping is a prominent retailer specializing in vaping products, offering a diverse selection of vaping devices, kits and accessories.',
            products : 'Vapes',
        },
    ];
}

export {
    partners,
}