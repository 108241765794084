<script setup>
import {nextTick, onBeforeMount, onDeactivated, onMounted, ref} from "vue";
import {get} from "@/storage";

const input = ref(null);
const error = ref(false);

// eslint-disable-next-line no-undef
const emit = defineEmits(['answered']);

onBeforeMount(() => {
    nextTick(() => {
        document.title = 'Question 2';
    });
});
onMounted(() => {
    const value = get('question-2');

    if (value) {
        input.value = get('question-2').answer;
    }
})

const validate = () => (input.value !== '');

// eslint-disable-next-line no-unused-vars

const handleChange = (value) => {
    if ( ! validate()) {
        error.value = true;
    }

    input.value = value;

    emit('answered', {
        number: 2,
        question:'What do you smoke?',
        answer:value,
        valid:validate(),
    });
}

onDeactivated(() => {
    error.value = false;
});
</script>

<template>
    <div class="question">
        <h1 class="mb-5">What do you smoke?</h1>
        <div class="row">
            <div class="col-md-4 text-center">
                <button type="button" :class="{active: input === 'Rolling Tobacco'}" class="mx-auto px-3 py-4 button d-block" @click="handleChange('Rolling Tobacco')">
                    Rolling Tobacco
                </button>
            </div>
            <div class="col-md-4 text-center">
                <button type="button" :class="{active: input === 'Cigarettes'}" class="mx-auto px-3 py-4 button d-block" @click="handleChange('Cigarettes')">
                    Cigarettes
                </button>
            </div>
            <div class="col-md-4 text-center">
                <button type="button" :class="{active: input === 'Both'}" class="mx-auto px-3 py-4 button d-block" @click="handleChange('Both')">
                    Both
                </button>
            </div>
        </div>
        <div class="mt-5" v-if="error">
            <div class="alert alert-danger">
                Please chose one of the options
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>