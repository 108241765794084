<script setup>
import {nextTick, onBeforeMount, onDeactivated, onMounted, ref} from "vue";
import {get} from "@/storage";
// eslint-disable-next-line no-undef
const emit = defineEmits(['answered', 'showResults', 'results', 'finished']);

const input = ref(null);
const error = ref(false);

onBeforeMount(() => {
    nextTick(() => {
        document.title = 'Question 5';
    });
});

onMounted(() => {
    const value = get('question-5');

    if (value) {
        input.value = get('question-5').answer;
    }
});

const handleChange = (value) => {
    if (value === '') {
        error.value = true;
    }

    input.value = value;
    emit('answered', {
        number: 5,
        question:'Have you tried to quit before?',
        answer:value,
        valid:true,
    });

    if (value === 'No') {
        emit('finished', true);
    }
}

onDeactivated(() => {
    error.value = false;
});
</script>

<template>
    <div class="question">
        <h1 class="mb-5">Have you tried to quit before?</h1>
        <div class="row">
            <div class="col-md-6">
                <button type="button" :class="{active: input === 'Yes'}" class="mx-auto px-3 py-4 button d-block my-4" @click="handleChange('Yes')">
                    Yes
                </button>
            </div>
            <div class="col-md-6">
                <button type="button" :class="{active: input === 'No'}" class="mx-auto px-3 py-4 button d-block my-4" @click="handleChange('No')">
                    No
                </button>
            </div>
        </div>
        <div class="alert alert-danger" v-if="error">
            Please chose one of the options
        </div>
    </div>
</template>

<style scoped>

</style>