<script setup>
import {nextTick, onBeforeMount, onDeactivated, onMounted, ref} from "vue";
import {get} from "@/storage";
// eslint-disable-next-line no-undef
const emit = defineEmits(['answered']);

const input = ref(null);
const error = ref(false);
const answers = ref([]);

onBeforeMount(() => {
    nextTick(() => {
        document.title = 'Question 4';
    });
});

onMounted(() => {
    const value = get('question-4');

    if (value) {
        input.value = get('question-4').answer;
    }
});

const handleChange = (value) => {
    if (value === '') {
        error.value = true;
    }

    if (answers.value.indexOf(value) >= 0) {
        answers.value.splice(answers.value.indexOf(value), 1);
    } else {
        answers.value.push(value);
    }

    answers.value = [...new Set(answers.value)];

    input.value = value;
    emit('answered', {
        number: 4,
        question:'What are your reasons for wanting to quit?',
        answer:JSON.stringify(answers.value),
        valid:true,
    });
}

onDeactivated(() => {
    error.value = false;
});
</script>

<template>
    <div class="question">
        <h1 class="">What are your reasons for wanting to quit? </h1>
        <div class="text-sm-center text-muted">Select multiple</div>
        <div class="row">
            <div class="col-md-6">
                <button type="button" :class="{active: answers.indexOf('Health') >= 0}" class="mx-auto px-3 button d-block my-4" @click="handleChange('Health')">
                    Health
                </button>
                <button type="button" :class="{active: answers.indexOf('Too expensive')  >= 0}" class="mx-auto px-3 button d-block my-4" @click="handleChange('Too expensive')">
                    Too expensive
                </button>
            </div>
            <div class="col-md-6">
                <button type="button" :class="{active: answers.indexOf('Environment') >= 0}" class="mx-auto px-3 button d-block my-4" @click="handleChange('Environment')">
                    Environment
                </button>
                <button type="button" :class="{active: answers.indexOf('Something else') >= 0}" class="mx-auto px-3 button d-block my-4" @click="handleChange('Something else')">
                    Something else
                </button>
            </div>
        </div>
        <div class="alert alert-danger" v-if="error">
            Please chose one of the options
        </div>
    </div>
</template>

<style scoped>

</style>