<script setup>
import {Database} from "@/database";
import {onMounted, ref} from 'vue';

const db = new Database('app');

const settings  = ref('');
const apiKey    = ref('');
const name      = ref('');

onMounted(async () => {
    await db.get('settings', 'settings').then((response) => {
        settings.value  = response;
        apiKey.value    = response?.api_key;
        name.value      = response?.name;
    });
});

const saveChanges = async () => {
    await db.add('settings', {
        api_key: apiKey.value,
        name: name.value,
    }, 'settings');
}
</script>

<template>
    <div class="m-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 offset-3">
                    <div class="card">
                        <div class="card-header">
                            <h2 class="card-title">Settings</h2>
                        </div>
                        <div class="card-body">
                            <form class="text-start">
                                <div class="mb-3">
                                    <label for="api-key" class="form-label fw-bold">Machine Name</label>
                                    <input v-model="name" type="text" class="form-control text-start" id="name" placeholder="Enter machine name">
                                </div>
                                <div class="mb-3">
                                    <label for="api-key" class="form-label fw-bold">API Key</label>
                                    <input v-model="apiKey" type="text" class="form-control text-start" id="api-key" placeholder="Enter app API Key">
                                </div>
                            </form>
                        </div>
                        <div class="card-footer text-end">
                            <router-link to="/" class="btn btn-outline-secondary" style="margin-right: 10px;">Cancel</router-link>
                            <button type="button" class="btn btn-primary" @click="saveChanges()">
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>