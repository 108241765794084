<script setup>
import {onBeforeMount, onDeactivated, onMounted, ref, nextTick} from "vue";
import {get} from "@/storage";
import NumberPad from "@/components/NumberPad.vue";

const input = ref('');
const inputRef = ref(null);

const blur = () => {
    console.log('Blur..');
    // inputRef.value.blur();
    handleChange();
}

// eslint-disable-next-line no-undef
const emit = defineEmits(['answered']);
const error = ref(false);

onBeforeMount(() => {
    nextTick(() => {
        document.title = 'Question 1';
    });
});

onMounted(() => {
    const value = get('question-1');

    if (value !== null) {
        input.value = value.answer;
    }
});

onDeactivated(() => {
    handleChange();
});
const validate = () => {
    if ((parseInt(input.value) > 18) && (parseInt(input.value) < 110)) {
        return false;
    }

    return error.value = true;
    // return error.value = (parseInt(input.value) < 18);
    // return error.value = (parseInt(input.value) > 110);
};

const updateInput = (key) => {
    error.value = false;
    if (parseInt(key) > 0) {
        console.log('Is a number');
        input.value = `${input.value}${key}`;
        handleChange();
        validate();
    } else {
        error.value = false;
        input.value = '';
    }
}

// eslint-disable-next-line no-unused-vars
const handleChange = (event) => {
    error.value = false;
    emit('answered', {
        number: 1,
        question:'What is your age?',
        answer:input.value,
        valid: !validate(),
    });
}
</script>

<template>
    <div class="question">
        <h1 class="mb-5">What is your age?</h1>
        <div>
            <input readonly type="number" value="18" min="18" max="100" size="3" v-model="input" ref="inputRef" @input="handleChange" @change="validate()" @focusout="validate()" @blur="blur" />
            <div class="mt-5" v-if="error">
                <div class="alert alert-danger">
                    You must be between 18 and 110 to use this service
                </div>
            </div>
            <NumberPad @number-pressed="updateInput" />
        </div>
    </div>
</template>

<style scoped>

</style>