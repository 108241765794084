<script setup>
import PageTitle from "@/components/PageTitle.vue";
import 'vue3-carousel/dist/carousel.css'
import router from "@/router";

const filterName = (name) => {
    router.push(`facts?name=${name}`);
}

const filterCategory = (category) => {
    router.push(`facts?category=${category}`);
}
</script>

<template>
    <div>
        <PageTitle />
        <div class="content d-flex flex-column justify-content-center" style="height: calc(100vh - 570px)">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-uppercase">Know The Facts</h1>
                    <div class="text-muted">for a smoke-free future</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="text-center my-3">
                        <button class="px-3 py-4 button d-block" @click="filterName('Government')">
                            Government Facts
                        </button>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-center my-3">
                        <button class="px-3 py-4 button d-block" @click="filterName('NHS')">
                            NHS
                        </button>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-center my-3">
                        <button class="px-3 py-4 button d-block" @click="filterName('WHO')">
                            World Health Organisation
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-bar">
        <div class="row">
            <div class="col-md-3 flex-column justify-content-center">
                <h2 class="text-white">Filter Facts By</h2>
            </div>
            <div class="col-md-9 text-start d-flex gap-1 justify-content-between">
                <button type="button" class="btn btn-filter btn-lg" @click="filterCategory('All')">All</button>
                <button type="button" class="btn btn-filter btn-lg" @click="filterCategory('Health')">Health</button>
                <button type="button" class="btn btn-filter btn-lg" @click="filterCategory('Spending')">Spending</button>
                <button type="button" class="btn btn-filter btn-lg" @click="filterCategory('Lifestyle')">Lifestyle</button>
                <button type="button" class="btn btn-filter btn-lg" @click="filterCategory('Environment')">Environmental</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
h2 {
    margin: 10px 0 !important;
    font-size: 2.3vw;
}
.button {
    font-size: 1.5vw;
}
button.btn-filter {
    color: #ffffff;
    border: 3px solid #ffffff;
    border-radius: 600px;
    font-weight: bold;
    padding: 10px 30px;
    width: 100%;
    font-size: 1.5vw;
}
.filter-bar {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    background-color: #348bd8;
    padding: 24px;
}
</style>