const nrtProductInformation = () => {
    return [
        {
            name        :'Nicotine Patches',
            what        :'Nicotine patches are adhesive patches that deliver a controlled amount of nicotine through the skin. They are available in various strengths, allowing for a gradual reduction in nicotine intake.',
            how         :'The patch adheres to the skin, and nicotine is absorbed slowly which mimics the nicotine delivery from smoking without the harmful chemicals.',
            effect      :'Nicotine patches are a proven method to help people quit smoking, especially when used as part of a comprehensive smoking cessation program. They offer a continuous, steady supply of nicotine, reducing withdrawal symptoms.',
            side_effect :'Common side effects may include skin irritation, vivid dreams, or sleep disturbances.\n',
            uses        :'Apply the patch to a clean, dry, and hairless area of skin. Change the patch daily, rotating application sites to avoid skin irritation.',
        },
        {
            name        :'Nicotine Nasal Spray',
            what        :'Nicotine nasal spray is a fast-acting NRT that delivers nicotine through the nasal mucosa.',
            how         :'Nicotine is absorbed through the nasal mucosa, providing quick relief from cravings.\n',
            effect      :'Nicotine nasal spray is highly effective, particularly for individuals who require rapid relief from intense cravings.',
            side_effect :'Common side effects may include nasal irritation, sneezing, or coughing.',
            uses        :'Administer as directed by a healthcare professional. Avoid inhaling deeply during administration to prevent irritation.',
        },
        {
            name        :'Nicotine Mouth Spray',
            what        :'A spray containing a controlled dose of nicotine that is sprayed into the mouth.',
            how         :'Nicotine is absorbed through the mucous membranes in the mouth, providing a relatively quick reduction in cravings.',
            effect      :'Nicotine mouth spray is effective, offering a portable and discreet option for managing cravings.',
            side_effect :'Possible side effects include throat or mouth irritation.',
            uses        :'Administer the spray as directed, avoiding contact with the eyes.',
        },
        {
            name        :'Nicotine Inhaler',
            what        :'Resembles a cigarette, consisting of a mouthpiece and a cartridge containing nicotine. The nicotine inhaler is a device that allows users to inhale vaporised nicotine.',
            how         :'Users inhale and absorb vaporised nicotine through the mouth and throat, mimicking the hand-to-mouth motion of smoking.',
            effect      :'Nicotine inhalers are effective in providing a behavioral replacement for smoking and a controlled dose of nicotine.',
            side_effect :'Potential side effects include throat or mouth irritation.',
            uses        :'Inhale as needed, with a recommended maximum daily usage.',
        },
        {
            name        :'Nicotine Gum',
            what        :'Nicotine gum is a chewable gum containing a controlled dose of nicotine, it is an over-the-counter NRT.',
            how         :'Nicotine is absorbed through the oral mucosa, helping manage withdrawal symptoms.',
            effect      :'Nicotine gum is effective when used as part of a comprehensive smoking cessation plan.',
            side_effect :'Possible side effects include jaw discomfort or gastrointestinal issues.',
            uses        :'Chew gum slowly, stopping and parking it in the cheek when a tingling sensation occurs, then resume chewing.',
        },
        // {
        //     name        :'Nicotine Lozenges',
        //     what        :'Lozenges designed to be sucked, allowing the nicotine to be gradually absorbed through the oral mucosa.',
        //     how         :'The slow dissolution of the lozenge provides a controlled release of nicotine, helping manage cravings.',
        //     effect      :'Nicotine lozenges are effective in reducing withdrawal symptoms and cravings.',
        //     side_effect :'Possible side effects include throat irritation and indigestion.',
        //     uses        :'Allow the lozenge to dissolve in the mouth without chewing or swallowing.',
        // },
        {
            name        :'Nicotine Tablets',
            what        :'Small, easily dissolvable tablets containing nicotine.',
            how         :'Nicotine tablets deliver nicotine through the oral mucosa, offering a quick way to address cravings.',
            effect      :'Nicotine tablets are effective and discreet for managing cravings.\n',
            side_effect :'Potential side effects may include throat irritation.',
            uses        :'Place the nicotine tablets under the tongue and allow it to dissolve.',
        },
        {
            name        :'Nicotine Vapes',
            what        :'Electronic devices designed to simulate smoking by delivering nicotine without the combustion of tobacco. E- cigarettes, or vapes, are battery-powered devices that heat a liquid (often containing nicotine) into an aerosol, which is then inhaled.',
            how         :'E-cigarettes vaporize a liquid, typically containing nicotine, allowing users to inhale the aerosol.',
            effect      :'The effectiveness of e-cigarettes as a smoking cessation tool is still under debate, with varying opinions in the scientific community.',
            side_effect :'Side effects may include respiratory irritation, and the long-term health effects of vaping are still being studied.',
            uses        :'Follow the manufacturer\'s instructions for usage and maintenance.',
        },
        {
            name        :'Nicotine Pouches',
            what        :'Pouches that resemble small tea bags filled with a mixture of nicotine, flavorings, and plant-based fibers.',
            how         :'Nicotine is absorbed through the gums, providing a smoke-free alternative.',
            effect      :'Nicotine pouches can offer a smoke-free alternative, but their effectiveness as a smoking cessation tool is not well-established.',
            side_effect :'Possible side effects may include irritation of the gums.\n',
            uses        :'Place the pouch between the gum and lip, allowing the nicotine to be absorbed through the gums.',
        },

    ]
}

export default nrtProductInformation();