<script setup>
import PageTitle from "@/components/PageTitle.vue";
import ProductInformationCarousel from "@/components/ProductInformationCarousel.vue";
import nrtProductInformation from "@/nrtProductInformation";
import {ref} from "vue";
import http from '@/http.js';

const sent      = ref(false);
const sending   = ref(false);
const email     = ref(null);

const submit = async () => {
    if (email.value === null || email.value?.length === 0) return;
    sending.value = true;
    await http.post('/api/nrt/email', {
        email: email.value,
    }).then(() => {
        sent.value = true;
        email.value = null;
    }).finally(() => {
        sending.value = false;
    }).catch(() => {

    });
}
</script>
<template>
    <PageTitle />
<!--    <div class="margin-bottom"></div>-->
<!--    <div class="content" style="height: calc(100vh - 250px) !important;">-->
    <div class="content">
        <h2 class="text-start">What are Nicotine replacement therapies (NRTs)?</h2>
        <div class="row">
            <div class="col-sm-8">
                <p class="text-start">
                    Nicotine Replacement Therapies (NRTs) are products which have been used for many years as a safe treatment to help wean individuals off tobacco by supplying a regulated amount of nicotine without the harmful substances found in cigarette smoke overall alleviating the withdrawal symptoms and cravings associated with quitting smoking, allowing individuals to gradually reduce their dependence on nicotine. NRTs come in various forms, such as patches, gum, lozenges, inhalers, nasal sprays, and more...
                </p>
            </div>
            <div class="col-sm-4 border p-3 rounded">
                <h5>Send Me Information</h5>
                <p class="small" style="font-size: 1rem">
                    Enter your email below to receive NRT info pack
                </p>
                <div class="input-group">
                    <input v-model="email" type="email" class="form-control" placeholder="Enter your email address" aria-label="Email Address" aria-describedby="button-addon2" required>
                    <button @click="submit" class="btn btn-success" type="button" id="button-addon2" :disabled="sending">
                        <span v-if="!sending">Send</span>
                        <span v-else>Sending</span>
                    </button>
                </div>
                <div class="mt-2" v-if="sent">
                    <span class="text-center p-2 sent">Sent. Please check your inbox.</span>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <div>
                    <ProductInformationCarousel :items="nrtProductInformation" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 small text-muted">
                It's essential for users to consult with healthcare professionals for personalised advice on choosing and using NRTs for smoking cessation.
            </div>
        </div>
    </div>
    <footer>
        <div class="text-center">
            <router-link :to="{name:'information'}" class="button px-4 py-2">
                Go Back
            </router-link>
        </div>
    </footer>
</template>

<style scoped>
.content {
    font-size: 1.3vw;
}
.sent {
    font-size: 1rem;
    color: #198754;
}
</style>