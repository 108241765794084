<script setup>
import {nl2br} from "@/views/strHelpers";
// eslint-disable-next-line no-undef
const props = defineProps({
    fact:Object
});
</script>

<template>
    <div class="carousel__item card-fieldset _my-2 w-50">
        <fieldset>
            <legend>
                <strong>{{ props.fact.name }} - {{ props.fact.category }}</strong>
            </legend>
            <div class="card-content">
                <p v-html="nl2br(props.fact.fact)"></p>
            </div>
        </fieldset>
    </div>
</template>

<style scoped>
fieldset {
    border: 2px solid #139BD3;
    border-radius: 8px;
}
fieldset legend {
    padding: 10px;
    margin-left: 10px;
    text-align: left;
    float: none;
    width: auto;
}
.card-content {
    padding: 10px 30px;
}
.card-content p {
    text-align: left;
    font-size: 1.5vw;
    line-height: 3rem !important;
}
</style>